.insta-feed {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 3% 0 3% 0;
}

@media only screen and (max-width: 800px) {
    .insta-feed {
        padding: 6% 0 6% 0;
    }
}