.bg {
    background-image: url('../../Assets/bg.png');
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
}

.lu-holder {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 10% 0 10% 0;

    font-family: 'Bronova';
    font-size: calc(1 * (1vw + 1vh - 1vmin));
    font-size: 1vmax;
    color: var(--default-primary-color);
}
.lu-holder>div {
    padding-right: 40%;
}
.lu-title {
    padding-bottom: 15%;
}
.lu-holder>div>div>span {
    background-color: var(--default-secondary-color);
    padding: 3.5%;
}
.lu-holder>div>div>ul {
    list-style: none;
}
.lu-holder>div>div>ul>p {
    padding: 2.5% 0 2.5% 0;
}
.lu-holder>div>div>ul>li {
    padding: 1.5% 0 1.5% 0;
}
.lu-holder>div>div>ul>li>a {
    text-decoration: none;
    color: inherit;
}
.lu-holder>div>div>ul>li>a:hover {
    color: var(--default-secondary-color);
}

@media only screen and (max-width: 800px) {
    .lu-holder {
        font-size: calc(1.8 * (1vw + 1vh - 1vmin));
        font-size: 1.8vmax;
    }
    .lu-holder>div {
        padding-right: 0;
    }
    .lu-holder>div>div>span {
        background-color: var(--default-secondary-color);
        padding: 3.5%;
    }
    .lu-holder>div>div>ul {
        list-style: none;
    }
    .lu-holder>div>div>ul>p {
        padding: 4.5% 0 4.5% 0;
    }
    .lu-holder>div>div>ul>li {
        padding-top: 3%;
    }
    .lu-holder>div>div>ul>li>a {
        text-decoration: none;
        color: inherit;
    }
    .lu-holder>div>div>ul>li>a:hover {
        color: var(--default-secondary-color);
    }
}