:root {
    --footer-bg-color: white;
    --default-primary-color: #133452;
    --default-secondary-color: #dab993;
}

.footer {
    background-color: var(--footer-bg-color);
    width: 100%;
    font-family: 'Bronova';
    font-size: calc(0.9 * (1vw + 1vh - 1vmin));
    font-size: 0.9vmax;
    color: var(--default-primary-color);
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 1.5% 0 1.5% 0;
}
.footer>span {
    padding: 0 1% 0 1%;
}

.footer-map-marker {
    color: grey;
    height: calc(2 * (1vw + 1vh - 1vmin)) !important;
    height: 2vmax !important;
    width: auto;
}

.footer-wpp {
    font-size: calc(1 * (1vw + 1vh - 1vmin)) !important;
    font-size: 1vmax !important;
}

@media only screen and (max-width: 800px) {
    .footer {
        font-size: calc(1.2 * (1vw + 1vh - 1vmin));
        font-size: 1.2vmax;
        padding: 3.5% 0 3.5% 0;
    }
    .footer>span {
        padding: 0 2% 0 2%;
    }

    .footer-map-marker {
        height: calc(4.5 * (1vw + 1vh - 1vmin)) !important;
        height: 4.5vmax !important;
        width: auto;
    }
}