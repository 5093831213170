@font-face {
    font-family: 'Bronova';
    src: url('../../Assets/Fonts/Bronova-Regular.woff');
}

:root {
    --header-bg-color: white;
    --default-primary-color: #133452;
    --default-primary-color-hover: #0b1e30;
    --default-secondary-color: #dab993;
}

* {
    box-sizing: border-box;
    padding: 0;
    margin: 0;
}

.header {
    background-color: var(--header-bg-color);
    color: var(--default-primary-color);

    font-family: 'Bronova';
    font-size: calc(0.9 * (1vw + 1vh - 1vmin));
    font-size: 0.9vmax;

    width: 100%;
    
    display: flex;
    justify-content: center;
    align-items: center;
}
.header>div {
    padding: 1.5% 2% 1.5% 2%;
}

.header-logo {
    height: calc(2.8 * (1vw + 1vh - 1vmin));
    height: 2.8vmax;
    width: auto;
    cursor: pointer;
}

/* .header-sep {
    height: calc(2.8 * (1vw + 1vh - 1vmin));
    height: 2.8vmax;
    width: auto;
}

.header-social {
    display: inline-flex;
}
.header-social>span {
    white-space: nowrap;
    padding: 0 1% 0 1%;
} */

.header-map-marker {
    color: grey;
    height: calc(2 * (1vw + 1vh - 1vmin)) !important;
    height: 2vmax !important;
    width: auto;
}

.header-wpp {
    font-size: calc(1 * (1vw + 1vh - 1vmin)) !important;
    font-size: 1vmax !important;
}

@media only screen and (max-width: 800px) {
    .header {
        display: block;
        font-size: calc(1.2 * (1vw + 1vh - 1vmin));
        font-size: 1.2vmax;
        padding: 3.5% 0 3.5% 0;
    }
    .header>span {
        padding: 0;
    }

    .header-img-holder {
        display: flex;
        justify-content: center;
        align-items: center;
        padding-bottom: 2%;
    }

    .header-logo {
        height: calc(5.5 * (1vw + 1vh - 1vmin));
        height: 5.5vmax;
        width: auto;
    }

    /* .header-sep {
        display: none !important;
    }

    .header-social {
        border-top: 2px solid var(--default-secondary-color);
        padding: 3% 0 0 0;
    }
    .header-social>span {
        white-space: normal;
        padding: 0 2% 0 2%;
    } */

    .header-map-marker {
        height: calc(4.5 * (1vw + 1vh - 1vmin)) !important;
        height: 4.5vmax !important;
        width: auto;
    }
}
