.bg {
    background-image: url('../../Assets/bg.png');
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
}

.carousel {
    padding: 3% 5% 3% 5%;
}

.contacts-holder {
    padding: 3% 10% 3% 10%;
    width: 100%;
    display: inline-flex;
    justify-content: space-between;
    align-items: center;

    font-family: 'Bronova';
    font-size: calc(0.9 * (1vw + 1vh - 1vmin));
    font-size: 0.9vmax;

    color: var(--default-primary-color);
}
.contact {
    padding: 0 2% 0 2%;
}
.contact>a {
    display: inline-flex;
    text-decoration: none;
    color: inherit;
}
.contact>a:hover {
    color: var(--default-secondary-color);
}
.contact-icon {
    padding: 0 5% 0 5%;
    height: calc(2 * (1vw + 1vh - 1vmin));
    height: 2vmax;
    width: auto;
}
.local-link {
    display: inline-flex;
    white-space: nowrap;
    text-decoration: none;
    color: inherit;
    cursor: pointer;
}
.local-link:hover {
    color: var(--default-secondary-color);
}
.tel-text {
    display: inline-flex;
    cursor: pointer;
}
.tel-text:hover {
    color: var(--default-secondary-color);
}
.popup-content {
    width: 7.5vmax;
}
.tel-box {
    background: var(--default-primary-color);
    color: white;

    display: inline-flex;
    width: 100%;
    padding: 4%;

    text-align: center !important;
}

.bem-vindo {
    background-color: var(--default-primary-color);
    color: white;
    padding: 2.5% 0 2.5% 0;
    
    text-align: center;
    font-family: 'Bronova';
}
.bv-title {
    font-size: calc(2 * (1vw + 1vh - 1vmin));
    font-size: 2vmax;
}
.bv-barra {
    width: calc(42 * (1vw + 1vh - 1vmin));
    width: 42vmax;
}
.bv-text {
    font-size: calc(1.2 * (1vw + 1vh - 1vmin));
    font-size: 1.2vmax;

    line-height: calc(1.8 * (1vw + 1vh - 1vmin));
    line-height: 1.8vmax;

    padding-top: 2%;
}

.acompanhe-processo {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;

    padding-top: 3%;
}
.ap-link {
    font-family: 'Bronova';
    font-weight: bold;
    font-size: calc(1.2 * (1vw + 1vh - 1vmin));
    font-size: 1.2vmax;

    background: var(--default-secondary-color);

    -moz-border-radius: calc(2 * (1vw + 1vh - 1vmin));
    -webkit-border-radius: calc(2 * (1vw + 1vh - 1vmin));
    border-radius: calc(2 * (1vw + 1vh - 1vmin));
    border-radius: 2vmax;

    padding: 0.5% 2.5% 0.5% 2.5%;

    white-space: nowrap;
    text-decoration: none;

    color: var(--default-primary-color);
    
    text-align: center;
}
.ap-link:hover {
    background: #bda181;
}
.ap-icon {
    font-size: calc(1.8 * (1vw + 1vh - 1vmin)) !important;
    font-size: 1.8vmax !important;
    vertical-align: middle;

    padding-right: 2%;
}

.conheca {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 3% 0 3% 0;
}
.conheca-img-link {
    cursor: pointer;
}
.conheca-img-link>img {
    height: calc(7 * (1vw + 1vh - 1vmin));
    height: 7vmax;
    width: auto;
}
.conheca-text {
    text-align: center;
    vertical-align: top;

    font-size: calc(0.9 * (1vw + 1vh - 1vmin));
    font-size: 0.9vmax;
}
.conheca-btn {
    background-color: var(--default-primary-color);
    color: white;

    -moz-border-radius: calc(2 * (1vw + 1vh - 1vmin));
    -webkit-border-radius: calc(2 * (1vw + 1vh - 1vmin));
    border-radius: calc(2 * (1vw + 1vh - 1vmin));
    border-radius: 2vmax;

    font-family: 'Bronova';
    font-size: calc(1 * (1vw + 1vh - 1vmin));
    font-size: 1vmax;

    padding: 2.5% 10% 2.5% 10%;

    white-space: nowrap;
    border: none;
    text-decoration:none;
    cursor:pointer;
}
.conheca-btn:hover {
    background-color: var(--default-primary-color-hover);
}
.c-buttons-holder {
    padding-left: 3%;
}
.c-buttons-holder>div {
    padding: 2% 0 2% 0;
}

.presentation {
    background-color: var(--default-primary-color);
    color: white;

    width: 100%;
    padding: 3% 0 3% 0;

    font-family: 'Bronova';
    font-size: calc(0.9 * (1vw + 1vh - 1vmin));
    font-size: 0.9vmax;

    line-height: calc(1.5 * (1vw + 1vh - 1vmin));
    line-height: 1.5vmax;

    display: inline-flex;
    justify-content: center;
    align-items: center;
}
.presentation>img {
    height: calc(7 * (1vw + 1vh - 1vmin));
    height: 7vmax;
}
.presentation>div {
    padding: 0 2% 0 2%;
}

.apt-holder {
    width: 100%;
    display: block;
    margin-left: auto;
    margin-right: auto;
    padding: 3% 12% 3% 12%;
}
.apt>div {
    padding: 3.5% 0 3.5% 0;
}
.apt-box {
    width: 100%;
    display: inline-flex;

    background-color: var(--default-secondary-color);
    color: black;

    font-family: 'Bronova';
    font-size: calc(0.9 * (1vw + 1vh - 1vmin));
    font-size: 0.9vmax;

    -moz-border-radius: calc(2 * (1vw + 1vh - 1vmin));
    -webkit-border-radius: calc(2 * (1vw + 1vh - 1vmin));
    border-radius: calc(2 * (1vw + 1vh - 1vmin));
    border-radius: 2vmax;
}
.apt-box>img {
    height: calc(18 * (1vw + 1vh - 1vmin));
    height: 18vmax;

    -moz-border-radius: calc(2 * (1vw + 1vh - 1vmin)) 0 0 calc(2 * (1vw + 1vh - 1vmin));
    -webkit-border-radius: calc(2 * (1vw + 1vh - 1vmin)) 0 0 calc(2 * (1vw + 1vh - 1vmin));
    border-radius: calc(2 * (1vw + 1vh - 1vmin)) 0 0 calc(2 * (1vw + 1vh - 1vmin));
    border-radius: 2vmax 0 0 2vmax;

    vertical-align: middle;
}
.apt-box>span {
    padding: 3%;
}
.apt-box>span>p {
    padding-bottom: 5%;
}
.apt-box>span>ul {
    list-style: none;
}
.apt-box>span>ul>li::before {
    content: '\2022';
    color: var(--default-primary-color) !important;

    font-size: calc(2 * (1vw + 1vh - 1vmin));
    font-size: 2vmax;

    vertical-align: middle;
}

.areas-title {
    font-family: 'Bronova';
    font-size: calc(2 * (1vw + 1vh - 1vmin));
    font-size: 2vmax;

    color: var(--default-primary-color);
    
    text-align: center;
    padding: 1%;
}
.areas-holder {
    width: 100%;
    background-color: var(--default-primary-color);

    font-family: 'Bronova';
    font-size: calc(0.8 * (1vw + 1vh - 1vmin));
    font-size: 0.8vmax;

    color: white;
}
.areas-holder>div {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 2%;
}
.areas-holder>div>ul {
    width: 100%;
    list-style: none;
}
.areas-holder>div>ul>li::before {
    content: '\2022';
    color: var(--default-secondary-color) !important;

    font-size: calc(2 * (1vw + 1vh - 1vmin));
    font-size: 2vmax;

    vertical-align: middle;
}

.blog-title {
    font-family: 'Bronova';
    font-size: calc(2 * (1vw + 1vh - 1vmin));
    font-size: 2vmax;

    color: var(--default-primary-color);
    
    text-align: center;
    padding: 8% 0 0 0;
}

.map-holder {
    width: 100%;
    padding: 0 20% 4% 20%;
}

@media only screen and (max-width: 800px) {
    .contacts-holder {
        width: 100%;
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        justify-content: center;

        padding: 4.5% 0 4.5% 0;

        font-size: calc(1.4 * (1vw + 1vh - 1vmin));
        font-size: 1.4vmax;
    }
    .contact {
        flex: 50%;
        padding: 3% 5% 3% 5%;
    }
    .contact>a {
        display: inline-flex;
    }
    .contact-icon {
        object-fit: contain;
        padding-right: 4%;
        width: calc(5 * (1vw + 1vh - 1vmin));
        width: 5vmax;
        height: auto;
    }
    .popup-content {
        width: calc(7.5 * (1vw + 1vh - 1vmin));
        width: 17.5vmax;
    }
    
    .bem-vindo {
        padding: 4% 0 4% 0;
    }
    .bv-barra {
        width: calc(48 * (1vw + 1vh - 1vmin));
        width: 48vmax;
    }

    .acompanhe-processo {
        padding: 5% 0 2% 0;
    }
    .ap-link {
        font-size: calc(1.4 * (1vw + 1vh - 1vmin));
        font-size: 1.4vmax;

        -moz-border-radius: calc(4 * (1vw + 1vh - 1vmin));
        -webkit-border-radius: calc(4 * (1vw + 1vh - 1vmin));
        border-radius: calc(4 * (1vw + 1vh - 1vmin));
        border-radius: 4vmax;

        padding: 1.3% 7% 1.3% 7%;
    }
    .ap-icon {
        font-size: calc(3.7 * (1vw + 1vh - 1vmin)) !important;
        font-size: 3.7vmax !important;
    
        padding-right: 2%;
    }

    .conheca {
        padding: 5% 0 2% 0;
    }
    .conheca-img-link>img {
        height: calc(10 * (1vw + 1vh - 1vmin));
        height: 10vmax;
        width: auto;
    }
    .conheca-text {
        font-size: calc(1.6 * (1vw + 1vh - 1vmin));
        font-size: 1.6vmax;
    }
    .conheca-btn {
        -moz-border-radius: calc(2 * (1vw + 1vh - 1vmin));
        -webkit-border-radius: calc(2 * (1vw + 1vh - 1vmin));
        border-radius: calc(2 * (1vw + 1vh - 1vmin));
        border-radius: 2vmax;

        font-size: calc(1.4 * (1vw + 1vh - 1vmin));
        font-size: 1.4vmax;
        
        padding: 2.5% 10% 2.5% 10%;
    }
    .c-buttons-holder {
        padding-left: 3%;
    }
    .c-buttons-holder>div {
        padding: 2% 0 2% 0;
    }

    .presentation {
        padding: 4% 0 4% 0;

        font-size: calc(1 * (1vw + 1vh - 1vmin));
        font-size: 1vmax;
    }
    .presentation>img {
        height: calc(8 * (1vw + 1vh - 1vmin));
        height: 8vmax;
    }


    .apt-holder {
        width: 100%;
        display: block;
        margin-left: auto;
        margin-right: auto;

        padding: 5% 8% 5% 8%;
    }
    .apt-box {
        width: 100%;
        display: block;
        margin-left: auto;
        margin-right: auto;
    
        background-color: var(--default-secondary-color);
        color: var(--default-primary-color);
    
        font-family: 'Bronova';
        font-size: calc(1.5 * (1vw + 1vh - 1vmin));
        font-size: 1.5vmax;
    
        -moz-border-radius: calc(2 * (1vw + 1vh - 1vmin));
        -webkit-border-radius: calc(2 * (1vw + 1vh - 1vmin));
        border-radius: calc(2 * (1vw + 1vh - 1vmin));
        border-radius: 2vmax;
    }
    .apt-box>img {
        -moz-border-radius: calc(2 * (1vw + 1vh - 1vmin)) calc(2 * (1vw + 1vh - 1vmin)) 0 0;
        -webkit-border-radius: calc(2 * (1vw + 1vh - 1vmin)) calc(2 * (1vw + 1vh - 1vmin)) 0 0;
        border-radius: calc(2 * (1vw + 1vh - 1vmin)) calc(2 * (1vw + 1vh - 1vmin)) 0 0;
        border-radius: 2vmax 2vmax 0 0;
    
        vertical-align: middle;

        width: 100%;
        height: auto;
    }
    .apt-box>span>p {
        padding: 5%;
    }
    .apt-box>span>ul {
        padding: 5%;
    }
    .apt-box>span>ul>li {
        padding: 1.5% 0 1.5% 0;
    }

    .areas-title {
        font-size: calc(2.8 * (1vw + 1vh - 1vmin));
        font-size: 2.8vmax;

        padding: 2%;
    }
    .areas-holder {
        font-size: calc(1.7 * (1vw + 1vh - 1vmin));
        font-size: 1.7vmax;
    
        color: white;
    }
    .areas-holder>div {
        display: block;
        padding: 5% 10% 5% 10%;
    }
    .areas-holder>div>ul {
        width: 100%;
        list-style: none;
        padding: 0;
    }
    .areas-holder>div>ul>li {
        padding: 1.5% 0 1.5% 0;
    }
    .areas-holder>div>ul>li::before {
        content: '\2022';
        color: var(--default-secondary-color) !important;
    
        font-size: calc(2 * (1vw + 1vh - 1vmin));
        font-size: 2vmax;
    
        vertical-align: middle;
    }

    .blog-title {
        font-size: calc(2.8 * (1vw + 1vh - 1vmin));
        font-size: 2.8vmax;

        padding: 15% 0 0 0;
    }

    .map-holder {
        width: 100%;
        padding: 0 0 8% 0;
    }
}